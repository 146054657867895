import React, { Component } from "react"
import { ContactForm, Layout } from "../../../components"
import { Helmet } from "react-helmet"
import { H2, Medailon } from "@igloonet-web/shared-ui"
import Logo from "../../../images/reference/ife/logoife_small_1x.png"
import Fiser from "../../../images/reference/ife/fiser.png"
import Banner from "../../../images/reference/ife/brand-image_2x.jpg"
import LongImage from "../../../images/reference/ife/long_image.jpg"
import ReferenceHeader from "../../../components/reference-detail/reference-header"
import ReferenceImageText from "../../../components/reference-detail/reference-image-text"
import LongReferenceImage from "../../../components/reference-detail/long-reference-image"

class Ife extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>Náborová kampaň pro IFE-CR | igloonet</title>
          <meta
            name="description"
            content="Náborová kampaň pro strojírenského giganta s využitím online kanálů. Jak se nám povedlo navýšit životopisy o desítky měsíčně? Skvělý výsledek zvýšení návštěvnosti kariérní stránky o tisíce uživatelů měsíčně a posílení brandu o 25 % ve vyhledávání."
          />
        </Helmet>

        <ReferenceHeader
          companyName="IFE"
          heading="Marketingová kampaň"
          date="jaro 2018 - nyní"
        />

        <ReferenceImageText
          right
          img={Banner}
          eager
          alt=""
          logo={Logo}
          logoAlt="logo společnosti IFE"
          link="//hrdinouvife.cz/"
        >
          <h2>Cíle spolupráce</h2>
          <p>Náborová kampaň s využitím online kanálů</p>
          <ul>
            <li>Navýšení životopisů o desítky měsíčně</li>
            <li>
              Zvýšení návštěvnosti kariérní stránky o tisíce uživatelů měsíčně
            </li>
            <li>Zvýšení vyhledávání brandu o 25 %</li>
          </ul>
          <p>
            IFE-CR, a.s. nás na jaře 2018 oslovila s nabídkou možné spolupráce
            při náboru nových zaměstnanců do výroby. Ve výběrovém řízení na
            náborovou kampaň jsme získali největší důvěru, a tak jsme se pustili
            do práce. Obsadit jsme potřebovali pozice jako je svářeč, operátor
            montáže nebo přípravář lakovny. Demografické cílení v rámci kampaně
            tedy bylo zásadní a abychom klientovi zajistili desítky relevantních
            životopisů měsíčně, museli jsme se zaměřit na masový zásah. Pro
            správné vyhodnocení kampaní bylo nutné před startem kompletně
            nastavit analytiku webu.
          </p>
        </ReferenceImageText>

        <ReferenceImageText>
          <H2>Průběh spolupráce</H2>
          <p>
            Hrdiny výroby pro IFE-CR, a.s. jsme oslovovali nejen pomocí
            vyhledávacích sítí, ale také cíleně v obsahové síti Seznamu a Googlu
            podle zájmových, geografických a demografických možností cílení. K
            propagaci volných pracovních míst jsme využili i reklamu na sociální
            sítí Facebook.
          </p>
          <p>
            Změna zaměstnání není nákup trička na e-shopu. Pro uživatele jde o
            dlouhý rozhodovací proces, v kampani jsme proto volili informační a
            přesvědčovací reklamy a pomocí remarketingu jsme se jim poté nadále
            připomínali.
          </p>
        </ReferenceImageText>

        <LongReferenceImage src={LongImage} />

        <Medailon
          personName="Vít Fišer"
          position="HR marketing specialist"
          img={Fiser}
        >
          <p>
            igloonet nám pomohl navýšit objem životopisů řádově o desítky a
            návštěvnost kariérních stránek v řádech tisíců uživatelů měsíčně,
            díky čemuž jsme mohli obsadit mnoho volných pozic ve výrobě i
            kancelářích. Oceňuji zejména flexibilní reakce na naše požadavky,
            propracovanou strukturu online kampaní, transparentní komunikaci,
            pravidelný kvalitní reporting a aktivitu nad rámec domluvené
            spolupráce. V případě další kampaně v online prostředí bychom volili
            opět igloonet.
          </p>
        </Medailon>

        <ReferenceImageText>
          <H2>Shrnutí spolupráce</H2>
          <p>
            Podařilo se nám nasbírat relevantní a kvalitní životopisy uchazečů o
            práci v IFE-CR, a.s., výrazně jsme zvýšili návštěvnost kariérních
            stránek a rozšířili jsme povědomí o IFE-CR, a.s. napříč relevantní
            cílovou skupinou a jihomoravským regionem.
          </p>
        </ReferenceImageText>

        <ContactForm contact="adam" />
      </Layout>
    )
  }
}

export default Ife
